
/**********************************************************************


                      DETAIL BLOG
                      

**********************************************************************/

.single-layout {
  font-family: "Poppins", sans-serif !important;
}
.single-layout-breadcrumb .page-breadcrumb .breadcrumb {
  justify-content: center;
}
.single-layout-breadcrumb .page-breadcrumb .breadcrumb-item a {
  color: #666;
}
.blog-img-main {
  height: 600px;
  padding: 30px 0;
}
.blog-img-main img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.blog-title-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 30px;
}
.blog-title-wrap .author-date {
  padding-right: 20px;
}
.blog-title-wrap .author-date .author {
  padding-right: 20px;
}
.blog-title-wrap .author-date a {
  font-size: 14px;
  color: #666 !important;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  -ms-transition: 0.2s;
  transition: 0.2s;
}

.blog-title-wrap .category-tag-list .category-tag-name {
  display: inline-block;
}
.blog-title-wrap .category-tag-list .category-tag-name:after {
  content: "\f111";
  font-size: 5px;
  color: #a7a7a7;
  font-family: "Font awesome 5 free";
  font-weight: 600;
  padding: 0 5px;
}
.blog-title-wrap .category-tag-list .category-tag-name:last-of-type:after {
  content: "";
}
.blog-title-wrap .category-tag-list .category-tag-name {
  color: var(--color-primary);
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  -ms-transition: 0.2s;
  transition: 0.2s;
}
.blog-title-wrap .category-tag-list:hover .category-tag-name {
  color: #e24c07 !important;
}

.blog-title-wrap .title-font {
  font-size: 40px;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  font-weight: 700;
  color: black;
  font-family: "Poppins", sans-serif;
}

.blog-content-wrap {
  padding-left: 60px;
  padding-right: 60px;
}
.blog-desc p {
  font-size: 16px;
  font-weight: 400;
  margin: 15px 0;
  line-height: 1.8;
  font-family: "Poppins", sans-serif;
  text-align: justify;
  color: #666;
}
.blog-desc h2 {
  font-size: 25px;
}
.blog-desc h3 {
  font-size: 23px;
}
.blog-desc h4 {
  font-size: 20px;
}
.blog-desc h5 {
  font-size: 18px;
}
.blog-desc h6 {
  font-size: 16px;
}
.blog-desc blockquote {
  background: #f6f7fa;
  padding: 30px;
  padding-top: 60px;
  text-align: center;
  font-size: 20px;
  position: relative;
  font-style: italic;
  color: black;
  font-family: "Poppins", sans-serif;
  margin: 30px 0;
}
.blog-desc blockquote:before {
  content: "\f10e";
  font-size: 35px;
  font-family: "Font awesome 5 free";
  font-weight: 900;
  left: 50%;
  transform: translateX(-50%);
  top: 15px;
  position: absolute;
  color: #c7cad7;
}
.blog-desc img {
  height: 500px;
  width: 100%;
  object-fit: cover;
  margin: 30px;
}
.tags-wrap {
  display: flex;
  width: 100%;
  padding: 15px 0;
  justify-content: flex-end;
}
.blog-tags {
  display: flex;
  align-items: center;
  width: 50%;
}
.blog-tags .tags-list li {
  border-radius: 0;
}
.blog-tags p {
  font-weight: 600;
  padding-right: 10px;
  font-family: "Poppins", sans-serif;
}

.container-detail-blog {
  padding: 10px 30px;
}

.content-detail-blog {
  display: flex;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
}

@media (max-width: 575px) {
  .container-detail-blog {
    padding-left: 0px;
    padding-right: 0px;
  }
  .blog-content-wrap {
    padding-left: 0px;
    padding-right: 0px;
  }
  .content-detail-blog {
    display: flex;
    align-items: center;
    padding-left: 10px;

    padding-right: 10px;
  }

  .blog-img-main {
    height: 300px;
  }

  .blog-desc blockquote {
    padding: 10px;
  }

  .blog-desc img {
    margin: 0px;
  }
}
