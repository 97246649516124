@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap");

/* #225f2b */
:root {
  --color-primary: #438323 ;
  --color-primary-2: #225f2b49;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 224 71.4% 4.1%;
    --card: 0 0% 100%;
    --card-foreground: 224 71.4% 4.1%;
    --popover: 0 0% 100%;
    --popover-foreground: 224 71.4% 4.1%;
    --primary: 220.9 39.3% 11%;
    --primary-foreground: 210 20% 98%;
    --secondary: 220 14.3% 95.9%;
    --secondary-foreground: 220.9 39.3% 11%;
    --muted: 220 14.3% 95.9%;
    --muted-foreground: 220 8.9% 46.1%;
    --accent: 220 14.3% 95.9%;
    --accent-foreground: 220.9 39.3% 11%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 20% 98%;
    --border: 220 13% 91%;
    --input: 220 13% 91%;
    --ring: 224 71.4% 4.1%;
    --radius: 0.5rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }

  .dark {
    --background: 224 71.4% 4.1%;
    --foreground: 210 20% 98%;
    --card: 224 71.4% 4.1%;
    --card-foreground: 210 20% 98%;
    --popover: 224 71.4% 4.1%;
    --popover-foreground: 210 20% 98%;
    --primary: 210 20% 98%;
    --primary-foreground: 220.9 39.3% 11%;
    --secondary: 215 27.9% 16.9%;
    --secondary-foreground: 210 20% 98%;
    --muted: 215 27.9% 16.9%;
    --muted-foreground: 217.9 10.6% 64.9%;
    --accent: 215 27.9% 16.9%;
    --accent-foreground: 210 20% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 20% 98%;
    --border: 215 27.9% 16.9%;
    --input: 215 27.9% 16.9%;
    --ring: 216 12.2% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

@layer base {
  /* html {
    @apply text-surface;
    @apply bg-white;
  }
  html.dark {
    @apply text-neutral-50;
    @apply bg-body-dark;
  } */
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }

}
p {
  @apply leading-[1.6];
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: 0.85rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  font-family: "Poppins", sans-serif;
}
.PopoverContent {
  width: var(--radix-popover-trigger-width);
  max-height: var(--radix-popover-content-available-height);
}

.MegaMenuContent {
  width: var(--radix-navigation-menu-viewport-width);
  max-height: var(--radix-navigation-menu-viewport-height);
}


*::before,
*::after {
  box-sizing: border-box;
  transition: all 0.3s;
}
*:focus-visible {
  outline: unset;
}

a {
  color: unset;
  outline: unset;
  text-decoration: none !important;
}

.collapse {
  visibility: unset;
}

.footer-wrapper {
  background: url(./assets/images/back-foter.png) scroll no-repeat center top
    rgb(64, 64, 64);
  background-position: top;
  background-size: cover;
}

/* Define the counter style */

@counter-style finger-pointer {
  system: cyclic;
  symbols: "\1F449";
  suffix: " ";
}

ul.my-list-style {
  list-style-type: finger-pointer;
}

@media (min-width: 1024px) {
  .container {
    width: 970px !important;
  }
}

@media (min-width: 320px) {
  .container {
    max-width: 100% !important;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
}

/**************************************************************************************

                          SLIDE HOME

*************************************************************************************/
.slider-home .swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #000;
  opacity: 1;
  background: rgba(0, 0, 0, 0.2);
}

.slider-home .swiper-pagination-bullet-active {
  color: #fff;
  background: var(--color-primary);
}

.slider-home .swiper-button-prev::after,
.slider-home .swiper-button-next::after {
  width: 15rem;
  font-size: 15px !important;
  background: rgba(0, 0, 0, 0.5);
  padding: 15px;
  color: white !important;
  font-weight: 700;
}

/*************************************************************************

                         BLOG

*************************************************************************/

.swiper-carroussel-blog .swiper-pagination-bullet-active {
  background: var(--color-primary) !important;
}

.swiper-carroussel-blog .swiper-button-prev,
.swiper-carroussel-blog .swiper-button-next {
  left: unset !important;
  bottom: 10px !important;
  top: unset !important;
}

.swiper-carroussel-blog .swiper-button-prev {
  right: 150px !important;
}
.swiper-carroussel-blog .swiper-button-next {
  right: 50px !important;
}

@media (max-width: 460px) {
  .swiper-carroussel-blog .swiper-button-prev {
    right: 100px !important;
  }
  .swiper-carroussel-blog .swiper-button-next {
    right: 50px !important;
  }
}


.swiper-carroussel-blog .swiper-button-prev::after,
.swiper-carroussel-blog .swiper-button-next::after {
  font-size: 10px !important;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  color: white !important;
  font-weight: 700;
}

.trending-posts .sidebar-item:hover .num-left {
  background: var(--color-primary);
}
.trending-posts .sidebar-item:hover .content-right a {
  color: var(--color-primary);
}

.share-button {
  transition: opacity 0.8s ease, transform 0.4s ease;
}
.share-button:hover {
  filter: contrast(140%);
  transform: scale(1.1);
}

/*************************************************************************

                         Paginated

*************************************************************************/

.afs-paginate {
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
}
.afs-paginate .pagination {
  display: flex;
  justify-content: center;
  padding-top: 2rem;
}

.afs-paginate .page-link {
  border: 1px solid #dee2e6;
  color: #666;
  font-size: 15px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  text-align: center;
  justify-content: center;
  margin: 3px;
  font-weight: 600;
  transition: 0.2s;
  display: flex;
  align-items: center;
}

.afs-paginate .page-item.active .page-link {
  background-color: var(--color-primary) !important;
  border: 1px solid var(--color-primary) !important;
  color: white;
}

.afs-paginate .page-link:hover {
  background-color: var(--color-primary-2) !important;
  color: white;
}

.afs-paginate .page-link:focus {
  outline: unset !important;
}

.afs-paginate .pagination > li:last-child > a {
  border-bottom-right-radius: 50% !important;
  border-top-right-radius: 50% !important;
  outline: unset !important;
}

.afs-paginate .pagination > li:first-child > a {
  border-radius: 50%;
}

/*************************************************************************

                         Newsletter

*************************************************************************/

.newsletter {
  /* -webkit-transform: perspective(1800px) rotateY(20deg) skewY(1deg) */
  /* translateX(50px); */
  /* transform: perspective(1800px) rotateY(20deg) skewY(1deg) translateX(50px); */
  background: linear-gradient(80deg, #111111 0%, #454646 100%);
}